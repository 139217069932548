import HoverTooltip from '@deltek/specification-client/core/Feedbacks/HoverTooltip'
import { ListingDetail } from '@deltek/specification-client/core/Models/listing'
import { ListingLevel } from '@deltek/specification-client/core/Models/product'
import GridContainer from '@deltek/specification-client/core/Surfaces/GridContainer'
import GridItem from '@deltek/specification-client/core/Surfaces/GridItem'
import { pxToRem } from '@deltek/specification-client/core/Utilities/unitUtils'
import { ProductListingCardLogo } from '@deltek/specification-client/core/Widgets/ProductListing/Card/ProductListingCardLogo'
import ViewBuildingType from '@deltek/specification-client/core/Widgets/ProductListing/Details/GeneralInfo/ViewBuildingType'
import ViewDescription from '@deltek/specification-client/core/Widgets/ProductListing/Details/GeneralInfo/ViewDescription'
import ViewDetail from '@deltek/specification-client/core/Widgets/ProductListing/Details/GeneralInfo/ViewDetail'
import ViewName from '@deltek/specification-client/core/Widgets/ProductListing/Details/GeneralInfo/ViewName'
import ViewPublishedDate from '@deltek/specification-client/core/Widgets/ProductListing/Details/GeneralInfo/ViewPublishedDate'
import { removeFamilyNumberFromFamilyNameRegex } from '@deltek/specification-client/core/Widgets/ProductListing/productsUtils'
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import * as React from 'react'
import Truncate from 'react-truncate'
import { ListingDetailProperties } from './ListingDetailsProperties'

const useStyles = makeStyles((theme: Theme) => ({
    generalInfoContainer: {
        padding: theme.spacing(2),
        paddingRight: 0,
        display: 'block',
        color: '#474747',
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(2),
        },
    },
    font: { fontWeight: 500 },
    spacing: {
        marginTop: theme.spacing(1),
        alignItems: 'baseline',
    },
    layoutContainer: {
        overflowY: 'auto',
        width: '100%',
    },
    family: {
        marginLeft: theme.spacing(1),
        fontWeight: 500,
    },
    typographySpacing: {
        marginBottom: theme.spacing(1),
    },
    premiumSpacingLeft: { marginLeft: theme.spacing(0.5) },
    spacingLeft: { marginLeft: theme.spacing(1) },
    spacingRight: { marginRight: theme.spacing(1) },
    spacingTop: { marginTop: '-5px' },
    propertiesContainer: { marginTop: theme.spacing(2) },
    firmLogo: {
        width: 200,
        height: 60,
        objectFit: 'scale-down',
        objectPosition: 'left',
    },
    basicLayout: {
        marginBottom: theme.spacing(2),
    },
    productName: {
        fontSize: pxToRem(36),
        fontWeight: 500,
        lineHeight: 1,
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
        wordWrap: 'break-word',
    },
    buildingType: {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(1.75),
    },
    productInfo: { marginLeft: 'auto', marginRight: '45px' },
    spacingLeftMargin: { marginLeft: 'auto' },
    listingLogo: { display: 'flex', alignItems: 'center' },
    proprietaryBlock: {
        fontSize: pxToRem(13),
        color: '#FFFFFF',
        backgroundColor: '#FD3431',
        '& span': {
            margin: '3px',
        },
    },
    ProprietaryContainer: {
        borderRight: '1px solid #000000',
    },
    AGDspacing: { marginTop: theme.spacing(2) },
}))

interface ProductDetailsProps {
    productInfo: Partial<ListingDetail>
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
    productInfo,
}) => {
    const classes = useStyles()
    const getListingInfo = (listing: ListingLevel) => {
        let listingInfo: React.ReactNode = (
            <>
                <Typography variant="h6">{productInfo.type}</Typography>
                <Typography className={classes.spacingTop} variant="h6">
                    Listing
                </Typography>
            </>
        )
        switch (listing) {
            case ListingLevel.Premium:
                listingInfo = (
                    <>
                        <Typography variant="h6">Premium</Typography>
                        <ProductListingCardLogo
                            listingLevel={ListingLevel.Premium}
                        />
                    </>
                )
                break
            case ListingLevel.PremiumMasterSpec:
                listingInfo = (
                    <>
                        <Typography
                            className={classes.premiumSpacingLeft}
                            variant="h6"
                        >
                            Premium
                        </Typography>
                        <ProductListingCardLogo
                            listingLevel={ListingLevel.PremiumMasterSpec}
                        />
                    </>
                )
                break
            case ListingLevel.PremiumProprietary:
                listingInfo = (
                    <GridContainer className={clsx(classes.listingLogo)}>
                        <GridItem className={classes.ProprietaryContainer}>
                            <Typography
                                variant="h6"
                                className={classes.spacingRight}
                            >
                                Premium
                            </Typography>

                            <Typography
                                className={clsx(
                                    classes.proprietaryBlock,
                                    classes.spacingRight,
                                    classes.spacingTop
                                )}
                            >
                                <span>Proprietary</span>
                            </Typography>
                        </GridItem>
                        <GridItem
                            className={clsx(
                                classes.spacingLeft,
                                classes.AGDspacing
                            )}
                        >
                            {' '}
                            <ProductListingCardLogo
                                listingLevel={ListingLevel.PremiumProprietary}
                            />
                        </GridItem>
                    </GridContainer>
                )

                break

            default:
                break
        }
        return listingInfo
    }

    return (
        <GridContainer
            className={classes.generalInfoContainer}
            data-testid="productDetails"
        >
            <GridItem className={classes.listingLogo}>
                {productInfo.firmLogoUrl ? (
                    <HoverTooltip
                        text={productInfo.firmName}
                        children={
                            <img
                                src={productInfo.firmLogoUrl}
                                className={classes.firmLogo}
                                alt={productInfo.firmName}
                            />
                        }
                    />
                ) : (
                    <Truncate lines={2}>{productInfo.firmName}</Truncate>
                )}

                <GridItem
                    className={clsx(
                        productInfo.type !== ListingLevel.PremiumProprietary
                            ? classes.productInfo
                            : classes.spacingLeftMargin,
                        'listingInfoContainer'
                    )}
                >
                    {getListingInfo(
                        productInfo.type ?? ListingLevel.Manufacturer
                    )}
                </GridItem>
            </GridItem>

            <GridItem className={classes.basicLayout}>
                <GridContainer direction="column">
                    <ViewDetail
                        name="Model #"
                        value={productInfo.model}
                        variant="h6"
                    />
                    <GridItem xs={12}>
                        <ViewName productName={productInfo.productName ?? ''} />
                    </GridItem>
                </GridContainer>
                <Divider />
            </GridItem>

            <GridContainer
                item
                direction="column"
                className={classes.layoutContainer}
            >
                <GridItem>
                    <ViewDetail
                        name="Category"
                        value={productInfo.categories?.join(', ')}
                        variant="h6"
                    />
                </GridItem>
                <GridItem>
                    <ViewDetail
                        name="Family Number"
                        value={productInfo.sectionNumber}
                        variant="h6"
                    />
                </GridItem>
                <GridItem>
                    <ViewDetail
                        name="Family"
                        value={productInfo.sectionName
                            ?.replace(removeFamilyNumberFromFamilyNameRegex, '')
                            .toUpperCase()}
                        variant="h6"
                    />
                </GridItem>

                <ViewDescription
                    longDescription={productInfo.longDescription}
                />

                <GridItem className={classes.buildingType}>
                    <ViewBuildingType
                        buildingTypes={productInfo.buildingTypes}
                    />
                </GridItem>

                <Divider />
                <GridContainer item>
                    <GridContainer
                        item
                        lg={3}
                        md={5}
                        xs={6}
                        className={clsx(
                            classes.spacing,
                            classes.typographySpacing
                        )}
                    >
                        {productInfo.firstPublished && (
                            <ViewPublishedDate
                                label="First Publish Date"
                                date={productInfo.firstPublished}
                            />
                        )}
                    </GridContainer>
                    <GridContainer
                        item
                        direction="column"
                        lg={3}
                        md={5}
                        xs={6}
                        className={clsx(
                            classes.spacing,
                            classes.font,
                            classes.typographySpacing
                        )}
                    >
                        {productInfo.lastUpdated && (
                            <ViewPublishedDate
                                label="Last Publish Date"
                                date={productInfo.lastUpdated}
                            />
                        )}
                    </GridContainer>
                </GridContainer>
                <Divider />
                <ViewDetail
                    name="Product Type"
                    value={productInfo.productType}
                    variant="h5"
                    lefSideFontWeight={500}
                />
                <GridContainer item className={classes.propertiesContainer}>
                    {productInfo.properties && (
                        <ListingDetailProperties
                            properties={productInfo.properties}
                        />
                    )}
                </GridContainer>
            </GridContainer>
        </GridContainer>
    )
}
