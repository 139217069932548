import {
    ManufacturerProductPropertyDetail,
    ManufacturerProductPropertyOptionGroupDetail,
} from '@deltek/specification-client/core/Models/product'
export type PropertyType = Partial<ManufacturerProductPropertyDetail>[]

export type PropertiesType = Record<string, PropertyType>
export const isMasterSpecPropertyGroup = (
    propertyGroup: Partial<ManufacturerProductPropertyDetail>[]
) => !!propertyGroup.some((prop) => !prop.isPremiumPlus)

export const getMasterSpecPropertyGroup = (properties: PropertiesType) =>
    Object.entries(properties).reduce((acc: PropertiesType, [name, prop]) => {
        if (!!isMasterSpecPropertyGroup(prop)) {
            const validProperties = prop
                .filter(
                    (subProperty) => !(!name && !!subProperty.isPremiumPlus)
                )
                .reduce(
                    (
                        subPropAcc: Array<
                            Partial<ManufacturerProductPropertyDetail>
                        >,
                        subProp
                    ) => {
                        const validSubProp = (
                            subProp.optionGroups ?? []
                        ).some((optGrp) =>
                            optGrp.options?.some(
                                (option) =>
                                    (!!option.isSupported &&
                                        !!option.isPremiumPlus) ||
                                    !option.isPremiumPlus
                            )
                        )

                        if (!!validSubProp) {
                            if (!name && !!subProp.isPremiumPlus) {
                                return subPropAcc
                            }
                            subPropAcc.push(subProp)
                        }

                        return subPropAcc
                    },
                    []
                )

            if (!!validProperties.length) {
                acc[name] = validProperties
            }
        }
        return acc
    }, {})

export const getNonMasterSpecPropertyGroupArray = (
    properties: PropertiesType
) => {
    let tempPropsArray: Array<
        [string, Partial<ManufacturerProductPropertyDetail>[]]
    > = []

    Object.entries(properties).forEach(([propertyName, propertyGroup]) => {
        if (!isMasterSpecPropertyGroup(propertyGroup) || !propertyName) {
            let subPropArray: Partial<ManufacturerProductPropertyDetail>[] = []
            propertyGroup.forEach((subProperty) => {
                let optGrpArray: Partial<ManufacturerProductPropertyOptionGroupDetail>[] = []

                subProperty.optionGroups?.forEach((optGrp) => {
                    if (
                        optGrp.options?.some((option) => !!option.isSupported)
                    ) {
                        optGrpArray.push(optGrp)
                    }
                })

                if (optGrpArray.length) {
                    subPropArray.push({
                        ...subProperty,
                        optionGroups: optGrpArray,
                    })
                }
            })

            if (subPropArray.length) {
                if (propertyName === '') {
                    tempPropsArray = [
                        [propertyName, subPropArray],
                        ...tempPropsArray,
                    ]
                    return
                }
                tempPropsArray.push([propertyName, subPropArray])
            }
        }
    })
    return tempPropsArray
}

export const getNonMasterSpecPropertyGroup = (properties: PropertiesType) =>
    getNonMasterSpecPropertyGroupArray(properties).reduce(
        (acc: PropertiesType, [name, prop]) => {
            acc[name] = prop
            return acc
        },
        {}
    )

export const getNonPremiumProperty = (properties: PropertiesType) => {
    let nonPremiumObject: Array<
        [string, Partial<ManufacturerProductPropertyDetail>[]]
    > = []
    Object.entries(properties).forEach(([propertyName, propertyGroup]) => {
        const properties = propertyGroup.filter((prop) => !prop.isPremiumPlus)
        if (properties.length) {
            nonPremiumObject.push([propertyName, properties])
        }
    })

    return nonPremiumObject
}

export const getPropertyGroupSelectedData = ({
    selectedData,
    propertyGroup,
    type,
}: {
    selectedData: Record<string, Record<string, string[]>>
    propertyGroup: Record<string, PropertyType>
    type: string
}) =>
    !Object.keys(propertyGroup).length
        ? {}
        : Object.entries(selectedData).reduce(
              (
                  acc: Record<string, Record<string, string[]>>,
                  [propName, prop]: [
                      propName: string,
                      prop: Record<string, string[]>
                  ]
              ) => {
                  if (
                      Object.keys(propertyGroup).includes(propName) &&
                      propName !== ''
                  ) {
                      acc[propName] = prop
                  }

                  if (propName === '') {
                      const subProps = Object.entries(prop).reduce(
                          (
                              acc2: Record<string, string[]>,
                              [subPropname, subPropSelected]
                          ) => {
                              const isPropertyGroupHeaderEmpty = !!propertyGroup[
                                  ''
                              ]

                              if (
                                  (type === 'masterSpec' &&
                                      isPropertyGroupHeaderEmpty &&
                                      (!propertyGroup[''][
                                          propertyGroup[''].findIndex(
                                              (subProp) =>
                                                  subProp.subPropertyName ===
                                                  subPropname
                                          )
                                      ] ||
                                          !!propertyGroup[''][
                                              propertyGroup[''].findIndex(
                                                  (subProp) =>
                                                      subProp.subPropertyName ===
                                                      subPropname
                                              )
                                          ]?.isPremiumPlus)) ||
                                  (type === 'premiumPlus' &&
                                      isPropertyGroupHeaderEmpty &&
                                      !propertyGroup[''][
                                          propertyGroup[''].findIndex(
                                              (subProp) =>
                                                  subProp.subPropertyName ===
                                                  subPropname
                                          )
                                      ]?.isPremiumPlus)
                              ) {
                                  return acc2
                              }
                              acc2[subPropname] = subPropSelected

                              return acc2
                          },
                          {}
                      )

                      if (!!Object.keys(subProps).length) {
                          acc[propName] = subProps
                      }
                  }
                  return acc
              },
              {}
          )
