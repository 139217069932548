const premiumPrimary = '#136BFB'
const premiumSecondary = '#2213FB'
const errorColor = '#FF0000'
const masterPrimary = '#10B091'
const masterSecondary = '#0A6654'
const propertiesTitle = '#04325B'
const buttonSecondary = '#0098D6'
const buttonPrimary = '#13B5EA'
const defaultLight = '#FFFFFF'
const boxBackground = '#EEECEC'
const propertiesDivider = '#BDBDBD'
const boxBorder = '#ACACAC'
const defaultSecondary = '#9E9E9E'
const productDetailsText = '#909192'
const defaultPrimary = 'rgba(186, 186, 186, 0.4)'
const titleColor = '#242424'
const defaultDark = '#000000'
const inputBorder = '#DDDDDD'
const dragIcon = '#DADADA'
const checkButton = '#27AE60'
const textColor = '#5E5F61'
const addPropertyButton = '#82DAF6'
const premiumPlusHeader = '#474747'
const addOptionFillButton = '#9FE0D3'
const addOptionButtonSecondary = '#006958'
const addOptionButtonHover = '#8ECDC1'
const contentEditableToggle = '#000000'
const contentEditableToggleHoverFill = '#006958'

export default {
    premiumPrimary,
    premiumSecondary,
    errorColor,
    masterPrimary,
    masterSecondary,
    defaultPrimary,
    defaultSecondary,
    defaultDark,
    defaultLight,
    titleColor,
    buttonPrimary,
    buttonSecondary,
    boxBorder,
    boxBackground,
    propertiesTitle,
    propertiesDivider,
    productDetailsText,
    inputBorder,
    dragIcon,
    checkButton,
    textColor,
    addPropertyButton,
    premiumPlusHeader,
    addOptionFillButton,
    addOptionButtonSecondary,
    addOptionButtonHover,
    contentEditableToggle,
    contentEditableToggleHoverFill,
}
