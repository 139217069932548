import { ManufacturerProductPropertyDetail } from '@deltek/specification-client/core/Models/product'
import GridContainer from '@deltek/specification-client/core/Surfaces/GridContainer'
import GridItem from '@deltek/specification-client/core/Surfaces/GridItem'
import { SwitchButton } from '@deltek/specification-client/core/Widgets/ProductListing/Details/SwitchButton'
import {
    getNormalizedProperties,
    getSelectedProperties,
} from '@deltek/specification-client/core/Widgets/ProductListing/Details/productDetailUtils'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { DetailProperties } from './DetailProperties'
import PremiumInfoIconTooltip from './PremiumInfoIconTooltip'

const useStyles = makeStyles(() => ({
    containerFullWidth: {
        width: '100%',
    },
    fullViewHeading: {
        color: '#4F4F4F',
    },

    mainContent: {
        width: '100%',
        borderBottom: '6px solid #04325B',
        display: 'flex',
    },
    fullViewContainer: {
        width: 'fit-content',
        alignItems: 'center',
        marginLeft: 'auto',
    },

    title: {
        color: '#04325B',
        textTransform: 'uppercase',
        fontWeight: 500,
    },
}))

interface ListingDetailPropertiesProps {
    properties?: Partial<ManufacturerProductPropertyDetail>[]
}

export const ListingDetailProperties: React.FC<ListingDetailPropertiesProps> = ({
    properties,
}) => {
    const classes = useStyles()
    const [fullView, setFullView] = useState(false)
    const handleChange = () => {
        setFullView(!fullView)
    }

    return (
        <GridContainer data-testid="ListingProperties">
            <GridItem className={classes.containerFullWidth}>
                <GridContainer
                    item
                    direction="row"
                    data-testid="detailProperties"
                >
                    <>
                        <GridItem className={classes.mainContent} sm={12}>
                            <GridContainer>
                                <GridItem md={7} sm={6} xs={12}>
                                    <Typography
                                        variant="h6"
                                        component="span"
                                        className={classes.title}
                                    >
                                        Properties
                                    </Typography>
                                    <PremiumInfoIconTooltip />
                                </GridItem>
                                <GridItem md={5} sm={6} xs={12}>
                                    <GridContainer
                                        className={clsx(
                                            classes.fullViewContainer
                                        )}
                                    >
                                        <SwitchButton
                                            checked={fullView}
                                            onChange={handleChange}
                                        />
                                        <Typography
                                            variant="body2"
                                            component="span"
                                            className={classes.fullViewHeading}
                                        >
                                            View Full List of Properties
                                            Available
                                        </Typography>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                        <DetailProperties
                            isFullView={fullView}
                            properties={getNormalizedProperties(properties)}
                            selectedData={getSelectedProperties(properties)}
                        />
                    </>
                </GridContainer>
            </GridItem>
        </GridContainer>
    )
}
