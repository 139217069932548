import OptionTooltip from '@deltek/specification-client/core/Feedbacks/OptionTooltip'
import GridContainer from '@deltek/specification-client/core/Surfaces/GridContainer'
import GridItem from '@deltek/specification-client/core/Surfaces/GridItem'
import { pxToRem } from '@deltek/specification-client/core/Utilities/unitUtils'
import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import {
    INFO_ICON_URL,
    MANUFACTURER_OPTION,
    MASTERSPEC_OPTION,
    PROPERTY_VALUE,
} from './constants'
import colors from './listingColors'
const {
    defaultDark,
    defaultPrimary,
    defaultSecondary,
    masterPrimary,
    premiumPrimary,
} = colors

const useStyles = makeStyles((theme: Theme) => ({
    iconBase: {
        display: 'inline',
        width: pxToRem(20),
        height: pxToRem(20),
        marginLeft: theme.spacing(1.25),
        borderRadius: 3,
        cursor: 'pointer',
    },
    icon: {
        display: 'inline',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(1),
        width: pxToRem(20),
        height: pxToRem(20),
        verticalAlign: 'middle',
    },
    optionTextTooltip: {
        fontSize: pxToRem(12),
        fontWeight: 400,
        minWidth: 'fit-content',
        marginBottom: theme.spacing(1),
        color: masterPrimary,
    },
    mSOption: (props: PremiumInfoIconTooltipProps) => ({
        marginRight: props.editMode ? theme.spacing(1.75) : 0,
        marginLeft: props.editMode ? 0 : theme.spacing(1.75),
        color: defaultDark,
    }),
    checkedMSOption: {
        minWidth: 'fit-content',
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2.5),
        color: masterPrimary,
        '&::before': {
            content: "'\\2713'",
            display: 'inline-block',
            color: masterPrimary,
            marginRight: '0.2rem',
            marginLeft: '0.2rem',
        },
    },
    checkedManufacturerOption: {
        marginRight: theme.spacing(2.5),
        color: premiumPrimary,
        '&::before': {
            content: "'\\2713'",
            display: 'inline-block',
            color: premiumPrimary,
            marginRight: '0.2rem',
            marginLeft: '0.2rem',
        },
    },
    buttonManufacturerOption: {
        fontSize: pxToRem(13),
        borderRadius: pxToRem(20),
        lineHeight: '1.7',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        border: `1px solid ${premiumPrimary}`,
        color: premiumPrimary,
    },
    buttonManufacturerOptionSelected: {
        color: theme.palette.secondary.light,
        backgroundColor: premiumPrimary,
    },
    buttonOption: (props: PremiumInfoIconTooltipProps) => ({
        fontSize: pxToRem(13),
        borderRadius: pxToRem(20),
        lineHeight: '1.7',
        border: `1px solid ${
            props.editMode ? masterPrimary : defaultSecondary
        }`,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }),
    buttonMSOption: (props: PremiumInfoIconTooltipProps) => ({
        backgroundColor: masterPrimary,
        border: `1px solid ${
            props.editMode ? masterPrimary : defaultSecondary
        }`,
        color: theme.palette.secondary.light,
    }),
    buttonCheckedOption: {
        '&::before': {
            content: "'\\2713'",
            marginRight: '5px',
        },
    },
    editMSOptionText: {
        margin: theme.spacing(0.375, 0, 0, 3),
    },
    optionText: {
        paddingLeft: theme.spacing(1.5),
    },
    dialogDetailContainer: {
        height: pxToRem(35),
        alignItems: 'center',
    },
    buttonOptionDialog: {
        textAlign: 'center',
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
    buttonMSOptionSelected: {
        backgroundColor: defaultPrimary,
        color: defaultDark,
    },
    left: {
        marginLeft: 'auto',
        marginRight: theme.spacing(3),
    },
    listingDetailTooltip: (props: PremiumInfoIconTooltipProps) => ({
        maxWidth: pxToRem(400),
        width: props.editMode ? pxToRem(393) : pxToRem(358),
        height: 146,
        padding: props.editMode ? theme.spacing(2.5) : theme.spacing(2),
    }),
    tooltip: {
        '&.MuiTooltip-tooltipPlacementBottom': {
            top: 3,
            '& .MuiTooltip-arrow': {
                '&::after': {
                    left: 24,
                },
            },
        },
    },
    dialogTooltip: {
        maxWidth: pxToRem(400),
        width: pxToRem(378),
        height: pxToRem(223),
    },
    nonEditModeContainer: {
        marginLeft: theme.spacing(1.5),
    },
    editModeItemContainer: {
        margin: theme.spacing(0.375, 0, 0, 3),
    },
    mSOptionDialog: {
        marginLeft: 0,
        color: defaultDark,
    },
}))

interface PremiumInfoIconTooltipProps {
    editMode?: boolean
    isDialog?: boolean
}

export const PremiumInfoIconTooltip: React.FC<PremiumInfoIconTooltipProps> = (
    props
) => {
    const { editMode, isDialog } = props
    const classes = useStyles(props)

    const listingDetailCustomTooltip = useMemo(() => {
        const col1Size = editMode ? 4 : 3
        const col2Size = editMode ? 8 : 9
        const prefix = editMode ? 'selected' : 'compliant'
        return (
            <>
                <GridContainer
                    wrap="nowrap"
                    className={clsx({
                        [classes.nonEditModeContainer]: !editMode,
                    })}
                >
                    <GridItem
                        xs={4}
                        className={clsx(
                            { [classes.buttonOption]: editMode },
                            `${classes.optionTextTooltip} ${classes.mSOption}`
                        )}
                    >
                        {PROPERTY_VALUE}
                    </GridItem>
                    <GridItem
                        xs={col2Size}
                        className={clsx({
                            [classes.editMSOptionText]: editMode,
                        })}
                    >
                        {MASTERSPEC_OPTION}
                    </GridItem>
                </GridContainer>
                <GridContainer
                    wrap="nowrap"
                    className={clsx({
                        [classes.nonEditModeContainer]: !editMode,
                    })}
                >
                    <GridItem
                        xs={col1Size}
                        className={clsx(
                            {
                                [classes.buttonOption]: editMode,
                                [classes.buttonCheckedOption]: editMode,
                                [classes.buttonMSOption]: editMode,
                                [classes.checkedMSOption]: !editMode,
                            },
                            `${classes.optionTextTooltip}`
                        )}
                    >
                        {PROPERTY_VALUE}
                    </GridItem>
                    <GridItem
                        xs={col2Size}
                        className={clsx({
                            [classes.editModeItemContainer]: editMode,
                        })}
                    >
                        {MASTERSPEC_OPTION} {prefix}
                    </GridItem>
                </GridContainer>
                <GridContainer
                    wrap="nowrap"
                    className={clsx({
                        [classes.nonEditModeContainer]: !editMode,
                    })}
                >
                    <GridItem
                        xs={col1Size}
                        className={clsx(
                            {
                                [classes.buttonOption]: editMode,
                                [classes.buttonCheckedOption]: editMode,
                                [classes.buttonManufacturerOptionSelected]: editMode,
                                [classes.checkedManufacturerOption]: !editMode,
                            },
                            `${classes.optionTextTooltip}`
                        )}
                    >
                        {PROPERTY_VALUE}
                    </GridItem>
                    <GridItem
                        xs={col2Size}
                        className={clsx({
                            [classes.editModeItemContainer]: editMode,
                        })}
                    >
                        {MANUFACTURER_OPTION} {prefix}
                    </GridItem>
                </GridContainer>
            </>
        )
    }, [editMode, classes])

    const premiumPlusCustomTooltip = useMemo(() => {
        const col1Size = 5
        const col2Size = 7
        const prefix = 'selected'
        return (
            <>
                <GridContainer
                    wrap="nowrap"
                    className={classes.dialogDetailContainer}
                >
                    <GridItem
                        xs={col1Size}
                        className={clsx([
                            classes.buttonOption,
                            classes.mSOptionDialog,
                            classes.buttonOptionDialog,
                        ])}
                    >
                        {PROPERTY_VALUE}
                    </GridItem>
                    <GridItem xs={col2Size} className={classes.optionText}>
                        {MASTERSPEC_OPTION}
                    </GridItem>
                </GridContainer>
                <GridContainer
                    wrap="nowrap"
                    className={classes.dialogDetailContainer}
                >
                    <GridItem
                        xs={col1Size}
                        className={clsx([
                            classes.buttonOption,
                            classes.buttonMSOptionSelected,
                            classes.buttonOptionDialog,
                        ])}
                    >
                        {PROPERTY_VALUE}
                    </GridItem>
                    <GridItem xs={col2Size} className={classes.optionText}>
                        {MASTERSPEC_OPTION} {prefix}
                    </GridItem>
                </GridContainer>
                <GridContainer
                    wrap="nowrap"
                    className={classes.dialogDetailContainer}
                >
                    <GridItem
                        xs={col1Size}
                        className={clsx(
                            classes.buttonManufacturerOption,
                            classes.buttonOptionDialog
                        )}
                    >
                        {PROPERTY_VALUE}
                    </GridItem>
                    <GridItem xs={col2Size} className={classes.optionText}>
                        {MANUFACTURER_OPTION}
                    </GridItem>
                </GridContainer>
                <GridContainer
                    wrap="nowrap"
                    className={classes.dialogDetailContainer}
                >
                    <GridItem
                        xs={col1Size}
                        className={clsx(
                            classes.buttonManufacturerOption,
                            classes.buttonManufacturerOptionSelected,
                            classes.buttonOptionDialog
                        )}
                    >
                        {PROPERTY_VALUE}
                    </GridItem>
                    <GridItem xs={col2Size} className={classes.optionText}>
                        {MANUFACTURER_OPTION} {prefix}
                    </GridItem>
                </GridContainer>
            </>
        )
    }, [editMode, classes])

    return (
        <OptionTooltip
            title={
                isDialog ? premiumPlusCustomTooltip : listingDetailCustomTooltip
            }
            padding={14}
            tooltipClass={clsx(
                {
                    [classes.dialogTooltip]: isDialog,
                    [classes.listingDetailTooltip]: !isDialog,
                },
                classes.tooltip
            )}
            delay={1000}
        >
            <div
                className={clsx(
                    'propertyInfo',
                    { [classes.left]: isDialog },
                    classes.iconBase
                )}
                data-testid={`propertyInfo`}
            >
                <img
                    data-testid="imgPropertyInfo"
                    className={classes.icon}
                    src={INFO_ICON_URL}
                />
            </div>
        </OptionTooltip>
    )
}

export default PremiumInfoIconTooltip
